import { getAuth } from '@clerk/remix/ssr.server';
import { json, LoaderFunctionArgs } from '@remix-run/node';
import { Outlet, useLoaderData } from '@remix-run/react';

import { AppHeader } from '~/components/app-header';

export const loader = async (args: LoaderFunctionArgs) => {
  const auth = await getAuth(args);

  return json({
    isSignedIn: Boolean(auth.userId),
  });
};

export default function AppTemplate() {
  const data = useLoaderData<typeof loader>();

  return (
    <>
      <AppHeader isSignedIn={data.isSignedIn} />
      <Outlet />
    </>
  );
}
